<div class="container" fxLayout="column" fxLayoutAlign="center center">
    <!-- <div fxLayout="row" fxFlex="100%">
        <mat-card class="content-container">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>
                    <h1 class="blue">
                        “Curatenie si pospetime!”

                    </h1>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    Suntem onorati de multimea comentariilor favorabile de pe pagina noastră de facebook
                </p>
            </mat-card-content>
        </mat-card>
    </div> -->
    <br />
    <div fxLayout="row" fxFlex="100%">
        <mat-card class="content-container">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>
                    <h2 class="blue">
                        Spălătoria profesională de covoare “TOATE ANOTIMPURILE” Craiova

                    </h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    Aventura noastră începe în anul 2019 când în urma unei cercetări atente a pieței, am observat o
                    creştere în cererea de servicii de spălare şi curățare a covoarelor. Chiar dacă suntem la început de
                    drum, am pornit cu multă motivaţie şi dorinţă de a aduce
                    un plus de valoare locuitorilor din oraşul nostru, care să contribuie la îmbunătăţirea calităţii
                    vieţii. Dispunem de un spațiu modern şi echipamente de curățat şi de o
                    tehnologie inovativă de ultimă generație şi
                    o foarte mare încredere şi dăruire pentru ca serviciile oferite să fie de cea mai bună calitate.
                </p>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxLayout="row" fxFlex="100%"></div>
    <br />
    <div fxLayout="row" fxFlex="100%">
        <p-carousel [value]="images" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1" [circular]="true"
            [autoplayInterval]="3000" [responsiveOptions]="responsiveOptions">
            <ng-template let-image pTemplate="item">
                <div class="car-details" fxLayout="column">
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12">
                            <img src="{{ image.source }}" class="responsive" />
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
    <br />
    <h1 class="blue">ACTIVITATEA NOASTRA ESTE EXECUTATA CU PASIUNE SI RESPECT PENTRU CLIENTI

    </h1>
    <h2 class="blue"> Ne straduim sa oferim servicii de calitate si sa devenim cei mai buni din regiune!
        Garantam cu totul curatirea si prospetimea covoarelor dumneavostra.</h2>
    <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
        <div fxLayout="column" fxFlex="33%" class="border section-laundry">
            <div class="circle flex-box">
                <mat-icon color="primary" class="d-img">local_laundry_service</mat-icon>
            </div>
            Oferim clientilor nostri o serie de servicii de calitate. Incercam sa optimizam timpul de curatire si uscare in timp record.
        </div>
        <div fxLayout="column" fxFlex="33%" class="border section-services">
            <div class="circle flex-box">
                <mat-icon color="primary" class="d-img">hourglass_empty</mat-icon>

            </div>
            test
        </div>
        <div fxLayout="column" fxFlex="33%" class="border section-carpet">
            <div class="circle flex-box">
                <mat-icon color="primary" class="d-img">local_laundry_service</mat-icon>

            </div>
            test
        </div>

    </div>
</div>