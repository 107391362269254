<footer class="mat-toolbar mat-primary">
    <div class="docs-footer-list" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%" fxLayoutAlign="start start">
                <span fxLayout="column" fxLayoutAlign="start start">
                    <h2>Orar</h2>
                </span>
                <span fxLayout="column" fxLayoutAlign="start start">
                    Luni - Vineri 09:00-18:00
                </span>
                <span fxLayout="row" fxLayoutAlign="start start">
                    Sambătă 09:00-14:00
                </span>
            </div>
            <div fxLayout="column" fxFlex="100%" fxLayoutAlign="start start">
                <span fxLayout="row" fxLayoutAlign="start start">
                    <mat-icon>location_on</mat-icon>
                    Craiova, Str. Calea Severinului Nr.34G
                </span>
                <span fxLayoutAlign="start center">
                    <mat-icon>phone</mat-icon>

                    <a href="tel:0770 467 811"><span>0770 467 811</span>
                        <!-- /.sc_button_text -->
                    </a>
                </span>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100%">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2853.570732445783!2d23.763660615739372!3d44.33932551580938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4752d912c668ef71%3A0x4ef8ff0993806331!2sSpalatorie%20covoare%20Toate%20anotimpurile%20Craiova!5e0!3m2!1sro!2sro!4v1583357410502!5m2!1sro!2sro"
                width="100%" height="250" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </div>
    </div>
</footer>