import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../core/models/User.model';

import { AuthenticationService } from '../core/services/authenticate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  user: User[];
  invalidLogin: boolean;


  form: FormGroup;                    // {1}
  private formSubmitAttempt: boolean; // {2}

  constructor(private router: Router,
    private fb: FormBuilder,         // {3}
    private authenticationService: AuthenticationService, // {4}

    // private store: Store<AppState>
  ) {
    // toastrConfig.timeOut = 100;
  }

  ngOnInit() {
    // this.form = this.fb.group({     // {5}
    //   userName: ['', Validators.required],
    //   password: ['', Validators.required]
    // });
  }

  isFieldInvalid(field: string) { // {6}
    // return (
    //   (!this.form.get(field).valid && this.form.get(field).touched) ||
    //   (this.form.get(field).untouched && this.formSubmitAttempt)
    // );
  }

  onSubmit() {
    if (this.form.valid) {
      // this.authService.login(this.form.value); // {7}
      this.login(); // {7}
    }
    this.formSubmitAttempt = true;             // {8}
  }

  login(): void {
    const toastrConfig = { positionClass: 'toast-top-center' };
    this.authenticationService
      .login(this.username, this.password)
      .subscribe(data => {
        debugger
        this.user = data;
        if (data.length > 0) {
          this.router.navigate(['home']);
        } else {
          // this.toastrService.error('Ati tastat gresit username sau parola.', 'Username sau parola gresite!', toastrConfig);
          this.invalidLogin = true;
        }
      });
  }

  diagnostic() {
    // return JSON.stringify(this.model);
  }
}
