import { Component, OnInit, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const THEME_DARKNESS_SUFFIX = `-dark`;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private overlayContainer: OverlayContainer,
  ) { }

  ngOnInit(): void {

  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
}

