<p>contact works!</p>


<h1>Telefon</h1>
<h2>0770 467 811</h2>
<h1>
    Adresa
</h1>
<h2>
    Mun. Craiova, Str. Calea Severinului Nr.34G, Jud. Dolj
</h2>
<h1>
    tapis.lavaj@gmail.com
</h1>
NU suntem colaboratori sau asociati ai alte firme in domeniu din Craiova, suntem o firmă nouă și independentă realizată pe un proiect european cu utilaje noi de ultimă generație! <br> În privința locației ai grijă mereu ca să găsești SPĂLĂTORIA PROFESIONALĂ
DE COVOARE ” TOATE ANOTIMPURILE” - a 2 a din curte și deschisă de curând și caută SIGLA ” TOATE ANOTIMPURILE!<br>
