<div class="container">
  <!-- <app-content select="[outlet]"></app-content> -->
  <app-header></app-header>

  <!-- <app-page-carousel></app-page-carousel> -->

  <mat-card class="content-container">
    <router-outlet> </router-outlet>
  </mat-card>
  <!--Scroll to top-->
  <!-- <div class="scroll-to-top" [ngClass]="'show-scrollTop'">
    <button (click)="scrollToTop()">
      test
      <i class="fa fa-chevron-up"></i>
    </button>
  </div>
  <div class="scroll-to-top-whatsapp" [ngClass]="'show-scrollTop'">
    <button (click)="openWhatsapp()">
      whatsapp
      <i class="fa fa-chevron-up"></i>
    </button>
  </div>
  <div class="scroll-to-top-waze" [ngClass]="'show-scrollTop'">
    <button (click)="openWaze()">
      waze
      <i class="fa fa-chevron-up"></i>
    </button>
  </div>
  <div class="scroll-to-top" [ngClass]="'show-scrollTop'">
    <button (click)="openMessenger()">
      facebook
      <i class="fa fa-chevron-up"></i>
    </button>
  </div> -->
  <app-footer> </app-footer>
  <div [ngClass]="'show-scrollTop'">
    <!-- "gotoTop()" -->
    <button *ngIf="isShow" (click)="scrollToTop()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  </div>
</div>