<div fxLayout="row" fxFlex="100%">
    <p-carousel [value]="images" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1" [circular]="true"
        [autoplayInterval]="3000" [responsiveOptions]="responsiveOptions">
        <ng-template let-image pTemplate="item">
            <div class="car-details" fxLayout="column">
                <div class="p-grid p-nogutter">
                    <div class="p-col-12">
                        <img src="{{ image.source }}" class="responsive" />
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</div>