<div fxLayout="column" class="d-header">
  <div fxLayout="row" class="d-header-first" fxFlex fxFlexFill fxLayout.lt-sm="column">
    <div fxLayout="column" fxFlex="40%">
      <!-- style="width:250px; height: 100px;"  -->
      <img src="../assets/images/sigla.jpg" class="logo responsive" routerLink="/" />
    </div>
    <span fxFlex fxFill></span>

    <!-- <div fxLayout="column" fxFlex="33%"> -->
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="30%">
      <mat-icon color="primary">schedule</mat-icon>
      <span fxLayoutAlign="start start">
        Lun - Vin 09:00-18:00
      </span>
      <span fxLayoutAlign="start start">
        Sam &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09:00-14:00
      </span>
    </div>
    <!-- </div> -->
    <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlex="20%">
      <mat-icon color="primary">location_on</mat-icon>
      Craiova, Str. Calea Severinului Nr. 34G (Leroy Merlin Craiovița)
    </div> -->
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="30%">
      <mat-icon color="primary" fxLayoutAlign="center center" click="tel:0770 467 811">phonelink_ring</mat-icon>
      <a color="primary" class="a-button" href="tel:0770 467 811"><span>0770 467 811</span></a>
      <!-- <a color="primary" class="a-button" href="tel:0786 564 564"><span>0786 564 564</span></a> -->
    </div>
  </div>
  <!-- <span fxFlex fxFill></span>
  <div fxLayout="column" fxLayoutAlign="center start">
    <span fxLayoutAlign="start start">
      <mat-icon>phone</mat-icon>

      <a href="tel:0770 467 811"><span>0770 467 811</span>

      </a>
    </span>
    <span fxLayoutAlign="start center">
      <mat-icon>
        email
      </mat-icon>
      <a href="mailto:tapis.lavaj@gmail.com"><span>tapis.lavaj@gmail.com</span>

      </a>
    </span>
    <span fxLayoutAlign="start center">
      <span class="logo-fb">

      </span>
      <a href="https://www.facebook.com/SpalatoriaToateAnotimpurileCraiova"
        target="_blank">SpalatoriaToateAnotimpurileCraiova</a>
    </span>
  </div> -->
  <div fxLayout="row">
    <mat-sidenav-container class="sidenav-container" fxFlexFill>
      <mat-sidenav #drawer class="sidenav" fixedInViewport="true" #sidenav role="navigation">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
          <a mat-list-item href="/">
            <mat-icon color="primary" aria-hidden="false">home</mat-icon>Home
          </a>
          <a mat-list-item href="/preturi">
            <mat-icon color="primary" aria-hidden="false">attach_money</mat-icon>
            Preturi
          </a>
          <a mat-list-item href="/servicii">
            <mat-icon color="primary" aria-hidden="false">settings</mat-icon>
            Servicii
          </a>
          <!-- <a mat-list-item href="/promotii">Promotii</a> -->
          <a mat-list-item href="/despre">
            <mat-icon color="primary" aria-hidden="false">info</mat-icon>
            Despre
          </a>
          <a mat-list-item href="/contact">
            <mat-icon color="primary" aria-hidden="false">call</mat-icon>
            Contact
          </a>
          <a mat-list-item href="https://www.facebook.com/SpalatoriaToateAnotimpurileCraiova" target="_blank">
            <img class="primary social-media-img" src="../assets/images/fb-icon.png"
              alt="Facebook SpalatoriaToateAnotimpurileCraiova">
            Facebook

          </a>

          <!-- Contact -->
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <mat-toolbar color="primary" class="app-toolbar">
          <mat-toolbar-row class="app-toolbar-row">
            <div fxShow="true" fxHide.lt-md>
              <button mat-button routerLink="/">
                <mat-icon aria-hidden="false">
                  home</mat-icon>
                Home
              </button>
              <button mat-button routerLink="/preturi">
                <mat-icon aria-hidden="false">attach_money</mat-icon>
                Preturi
              </button>
              <button mat-button routerLink="/servicii">
                <mat-icon aria-hidden="false">settings</mat-icon>
                Servicii
              </button>
              <button mat-button routerLink="/despre">
                <mat-icon aria-hidden="false">info</mat-icon>
                Despre
              </button>
              <button mat-button routerLink="/contact">
                <mat-icon aria-hidden="false">call</mat-icon>
                Contact
              </button>
              <button mat-button click="goToLink('https://www.facebook.com/SpalatoriaToateAnotimpurileCraiova')">
                <img class="social-media-img" src="../assets/images/fb-icon.png"
                  alt="Facebook SpalatoriaToateAnotimpurileCraiova">
                <!-- Contact -->
              </button>
              <span class="toolbar-flex"></span>
              <span class="toolbar-spacer"></span>
            </div>

            <span class="menu-spacer"></span>
            <span fxFlex fxFill></span>

            <button align="end center" type="button" aria-label="Toggle sidenav" mat-icon-button
              (click)="drawer.toggle()" fxShow="true" fxHide.gt-sm>
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          </mat-toolbar-row>
        </mat-toolbar>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

</div>



<!-- <div style="height: 80vh;">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <span>Responsive Navigation</span>
      <span class="menu-spacer"></span>
      <div fxShow="true" fxHide.lt-md>
        <a href="#" mat-button>Menu Item 1</a>
        <a href="#" mat-button>Menu Item 2</a>
        <a href="#" mat-button>Menu Item 3</a>
        <a href="#" mat-button>Menu Item 4</a>
        <a href="#" mat-button>Menu Item 5</a>
        <a href="#" mat-button>Menu Item 6</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
        <a href="#" mat-list-item>Menu Item 1</a>
        <a href="#" mat-list-item>Menu Item 2</a>
        <a href="#" mat-list-item>Menu Item 3</a>
        <a href="#" mat-list-item>Menu Item 4</a>
        <a href="#" mat-list-item>Menu Item 5</a>
        <a href="#" mat-list-item>Menu Item 6</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>Main content</mat-sidenav-content>
  </mat-sidenav-container>
</div> -->