import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { LoginComponent } from './components/login/login.component';
import { MaterialModule } from './material/material.module';
// import { MatToolbarModule, MatIconModule, MatButtonModule, MatCardModule, MatProgressSpinnerModule } from '@angular/material';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';

import { ServicesComponent } from './components/services/services.component';
import { PricesComponent } from './components/prices/prices.component';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { MatAnimatedIconComponent } from './shared/components/mat-animated-icon/mat-animated-icon.component';
import { VarDirective } from './shared/directives/VarDirective';
import { HeaderComponent } from './components/header/header.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from './components/core/services/authenticate.service';
import { HttpClientModule } from '@angular/common/http';
import { PageCarouselComponent } from './components/page-carousel/page-carousel.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ContactComponent,
    AboutComponent,
    ContentComponent,
    FooterComponent,
    CarouselComponent,
    ServicesComponent,
    PricesComponent,
    MatAnimatedIconComponent,
    VarDirective, MatAnimatedIconComponent, HeaderComponent, PromotionsComponent, PageCarouselComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    CommonModule,    //  <================== this line
    FlexLayoutModule,
    GalleriaModule,
    CarouselModule,
    FormsModule,      //<----------make sure you have added this.
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  providers: [
    AuthenticationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
