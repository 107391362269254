import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../models/User.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Settings } from '../utils/settings';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of } from 'rxjs';

import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
    private settings = new Settings();
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    jwtHelper: JwtHelperService;

    private loggedIn = new BehaviorSubject<boolean>(false); // {1}

    get isLoggedIn() {
        return this.loggedIn.asObservable(); // {2}
    }

    constructor(private http: HttpClient
        , private router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(new User());
        // (localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : '');
        this.currentUser = this.currentUserSubject.asObservable();
        // this.jwtHelper = jwtHelperService;
    }

    public get currentUserValue(): User {
        const t = localStorage.getItem('access_token');
        if (t && t.length > 0) {
            this.loggedIn.next(true);

            const myRawToken = t;
            const helper = new JwtHelperService();

            const decodedToken = helper.decodeToken(myRawToken);
            const expirationDate = helper.getTokenExpirationDate(myRawToken);
            const isExpired = helper.isTokenExpired(myRawToken);

            const newUser = new User();
            newUser.Password = decodedToken['Password'];
            newUser.Email = decodedToken['Email'];
            newUser.IsAdmin = decodedToken['IsAdmin'];

            this.currentUserSubject.next(newUser);
        }
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {

        const t = localStorage.getItem('access_token');
        if (t) {
            const myRawToken = t;
            const helper = new JwtHelperService();

            const decodedToken = helper.decodeToken(myRawToken);
            const expirationDate = helper.getTokenExpirationDate(myRawToken);
            const isExpired = helper.isTokenExpired(myRawToken);
            if (t && t.length > 0) {
                this.loggedIn.next(true);

                const newUser = new User();
                newUser.Password = decodedToken['Password'];
                newUser.Email = decodedToken['Email'];
                newUser.IsAdmin = decodedToken['IsAdmin'];

                this.currentUserSubject.next(newUser);
            }
            const locations = of(t);

            return locations;
        } else {
            this.loggedIn.next(false);
            const helper = new JwtHelperService();
            return this.http.get<any>(this.settings.authenticateUrl,
                { params: { username: username, password: password } }
            )
                .pipe(map((token: any) => {

                    const myRawToken = token;
                    const decodedToken = helper.decodeToken(myRawToken);
                    const expirationDate = helper.getTokenExpirationDate(myRawToken);
                    const isExpired = helper.isTokenExpired(myRawToken);
                    if (token && token.length > 0) {
                        this.loggedIn.next(true);

                        localStorage.setItem('access_token', JSON.stringify(myRawToken));
                        const newUser = new User();
                        newUser.Password = decodedToken['Password'];
                        newUser.Email = decodedToken['Email'];
                        newUser.IsAdmin = decodedToken['IsAdmin'];

                        this.currentUserSubject.next(newUser);
                    }

                    return token;
                }));
        }


    }

    logout() {
        this.loggedIn.next(false);

        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        this.currentUserSubject.next(null); // {4}
        this.router.navigate(['/login']);
    }
}
