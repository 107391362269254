import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { CarouselComponent } from './components/carousel/carousel.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Spalatorie Toate Anotimpurile Craiova';
  windowScrolled: boolean;
  isShow: boolean;
  topPosToStartShowing = 100;

  constructor(private router: Router,
  ) {
    
  }

  public ngOnInit(): void {

  }
  @HostListener('window:scroll')

  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // console.log('[scroll]', scrollPosition);
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
  openWaze() {
    window.open("https://www.waze.com/ul?ll=40.75889500%2C-73.98513100&navigate=yes&zoom=17");
  }
  openWhatsapp() {
    window.open("whatsapp://send?phone=3464478983");
  }
  openMessenger() {
    window.open("https://www.messenger.com/t/104559094442959");
  }
}
