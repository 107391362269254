<mat-card class="example-card">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Calculator Preţ</mat-card-title>
        <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-content>
        <p>
            Timp de curatare: 24-48 ore.<br/> Respectam intregul proces de curatare:<br/> - 1.Colectare<br/> - 2.Spalare mecanizata<br/> - 3.Centrifugare<br/> - 4.Uscare in camera speciala<br/> - 5.Finisare<br/> - 6.Livrare<br/> - 7.Client fericit.
        </p>

        <form class="example-form" style="color:
                      #757575;width:'200px'">
            <div fxLayout="column" fxLayoutAlign="center center">
                <!-- <div>1. One</div> <div>2. Two</div> <div>3. Three</div> <div>4. Four</div>
          </div>
            <div fxAlign="column"> -->
                <mat-form-field appearance="outline" class="example-full-width" fxLayout="row" fxLayoutAlign="center center">
                    <mat-label>Lungime</mat-label>
                    <input type="number" matInput>
                    <mat-icon matSuffix>mode_edit</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width" fxLayout="row" fxLayoutAlign="center center">
                    <mat-label>Latime</mat-label>
                    <input type="number" matInput>
                    <mat-icon matSuffix>mode_edit</mat-icon>
                </mat-form-field>
            </div>

        </form>

    </mat-card-content>
    <mat-card-actions>
        <button mat-button>Reseteaza</button>
        <button mat-button>Calculeaza</button>
    </mat-card-actions>
</mat-card>