<!-- {{diagnostic}} -->

<div class="loginPage">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Autentificare</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form class="loginForm" #loginForm="ngForm">

                <div class="bounds full-width" style="max-width: 70%; margin-left: 15%; margin-right: 15%">

                    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Utilizator</mat-label>
                            <input matInput type="email" placeholder="Utilizator" [(ngModel)]="username" name="username"
                                required minlength="4">
                            <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Parola</mat-label>
                            <input matInput type="password" placeholder="Parola" [(ngModel)]="password" name="password"
                                required minlength="6">
                            <mat-icon matSuffix>lock_open</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <div style="max-width: 70%; margin-left: 15%; margin-right: 15%">
                <button mat-raised-button [disabled]="!loginForm.form.valid" (click)="login()"
                    color="primary">Autentificare</button>
                <button mat-raised-button routerLink="/forgot" color="primary">Ai uitat parola?</button>
            </div>

        </mat-card-actions>
    </mat-card>
</div>

<!-- <div class="signin-content">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <p>Please login to continue</p>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="User" formControlName="userName" required>
            <mat-error *ngIf="isFieldInvalid('userName')">
              Please inform your user name
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Password" formControlName="password" required>
            <mat-error *ngIf="isFieldInvalid('userName')">
              Please inform your password
            </mat-error>
          </mat-form-field>
          <button mat-raised-button color="primary" type="submit">Login</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div> -->