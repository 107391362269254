import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../carousel/carousel.component';

@Component({
  selector: 'app-page-carousel',
  templateUrl: './page-carousel.component.html',
  styleUrls: ['./page-carousel.component.scss']
})
export class PageCarouselComponent implements OnInit {
  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  public images: any[];
  public responsiveOptions = [];

  constructor() {
    // this.router.events.subscribe((event: Event) => {
    //   this.navigationInterceptor(event);
    // });
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.images = [];
    this.images.push({ source: 'assets/images/images2.jpg', alt: 'Description for Image 1', title: 'Title 1' });
    this.images.push({ source: 'assets/images/images3.jpg', alt: 'Description for Image 2', title: 'Title 2' });
    this.images.push({ source: 'assets/images/images4.jpg', alt: 'Description for Image 3', title: 'Title 3' });
    this.images.push({ source: 'assets/images/images5.jpg', alt: 'Description for Image 4', title: 'Title 4' });
  }

}
