import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServicesComponent } from './components/services/services.component';
import { AboutComponent } from './components/about/about.component';
import { PricesComponent } from './components/prices/prices.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './services/auth.guard.service';


const routes: Routes = [
  // { path: '', component: HomeComponent, canActivate: [AuthGuard] },

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'servicii', component: ServicesComponent },
  { path: 'preturi', component: PricesComponent },
  { path: 'despre', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login200306', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
