<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                1. Preluarea Covorului
            </mat-panel-title>
            <mat-panel-description>
                Proces si procedee - descriere
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-label>Preluarea covorului se va realiza de la domiciliul clientului în intervalul stabilit de comun acord cu echipa noastră, care se va deplasa la adresa comunicată pentru ridicare, în baza comenzilor telefonice. În locație, accesul se face cu permisiunea
            dumneavoastră, colegii noştri dovedind profesionalism, bun simț şi bună creştere. Aspecte importante privind preluarea comenzii: - comunicarea gradului de uzură al covorului; - comunicarea către echipă, după caz, a încercărilor de curățare,
            aplicare şi a tipurilor de soluții folosite pe eventualele pete, anterior comenzii către noi.</mat-label>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                2.Procesul de spălare
            </mat-panel-title>
            <mat-panel-description>
                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p> Procesul de spălare a covoarelor dumneavoastra cu utilaje de ultima tehnologiecontinand 4 perii de curatare, fiecare fiind potrivita pentru un anumit grad de murdarie sipentru tipul fibrelor din covorul dumneavoastra. Procesul de spălare al covoarelor
            în mod profesional este unul distinct şi destul de complex, care cuprinde următoarele etape: - desprăfuirea covorului prin eliminarea initiala a prafului din el. - spălarea propriu-zisă se execută cu un utilaj complex. - 6 metri de covor se
            pot spăla în 3 minute. - întregul proces de spălare se realizează la o singură trecere: înmuiere, aplicare detergent, frecare, clătire şi rularea covorului. - se pot spăla toate tipurile de covoare cu o singură masină. - se vor aplica tratamente
            de calitate covoarelor. - aplicarea detergentului lichid si a balsamului, special concepute pentru covoare. Pentru covoarele din lână aplicăm un detergent care contine antioxidantii necesari eliminării efectului nociv al apei dure asupra lânii.
            Pentru covoarele care prezintămirosuri neplăcute (exemplu: urină), folosim un neutralizator de mirosuri care eliminăaceste mirosuri, fără a afecta esătura covorului. Iar pentru familiile cu copii, folosim un produs care contine germicid si
            antibacterial care distrug bacteriile din tesătura covorului. După procesul de spălare cu detergent covoarele se tratează cu balsam pentru atratarea fibrelor textile si pentru sustinerea procesului de limpezire. De asemenea, putem elimina
            petele de: cafea, ulei proaspăt, vin rosu, iarbă, mucegai, rugină, pix, cariocă, cerneală.</p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                3.Uscarea
            </mat-panel-title>
            <mat-panel-description>
                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p> Uscarea covorului se face în 2 etape: - Prima este introducerea covorului în centrifugă, proces prin care este eliminată 95% din apa din covor în trei minute. Centrifuga nu doar usucă covoarele, ci fixează culorile si permite clătirea aproape
            perfectă a covoarelor. - Cea de-a doua etapă este introducerea covoarelor într-un uscător încălzit, unde temperatura si umiditatea sunt controlate cu ajutorul unui computer. Uscătorul profesional de covoare, pe care îl avem în dotare, este
            conceput pentru încălzirea, dezumidificarea si uscarea încăperilor închise cu un volum mare, exprimat în metri cubi, ceea ce permite de fapt uscarea în 6-10 ore a covoarelor dumneavoastră. Această operaţiune va permite livrarea covorului la
            domiciliul dumneavoastră chiar în ziua următoare a preluării comenzii. Uscarea unui covor nestors va dura mult mai mult timp, existând riscul dezvoltării de bacterii şi microorganisme ce vor emana un miros neplăcut ulterior şi care pot duce
            la afecţiuni respiratorii.</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                4.Finisarea covorului
            </mat-panel-title>
            <mat-panel-description>
                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p> Finisarea covorului, etapă ce are loc înainte de livrare şi în care covoarele se aspiră din nou cu un aspirator puternic, dotat cu 3 motoare, iar apoi se periază manual cu perii special concepute să elimine firele de păr uman, scamele rezultate
            in procesul de spălare și alte impurități. Acestea sunt ulterior așezate pe suporți speciali, pregătite pentru livrare.</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                5.Tratament pentru franjurii covorului
            </mat-panel-title>
            <mat-panel-description>
                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p> Tratament pentru franjurii covorului După procesul de uscare, franjurii au parte de un tratament special, de albire cu un produs pe oxigen activ.</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                6.Livrarea la domiciliu
            </mat-panel-title>
            <mat-panel-description>
                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p> Pentru livrarea covorului veți fi contactați telefonic în vederea identificării unui interval orar compatibil echipei noastre şi mai ales favorabil dumneavoastră.</p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Observații
            </mat-panel-title>
            <mat-panel-description>
                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p> Eliminarea eficientă a petelor de pe covorul dumneavoastră va putea fi posibilă în cazul în care ați comunicat vechimea petei sau daca aceasta a fost tratată chimic cu alte substanțe înainte de spălare. Rezultatul final depinde foarte mult de
            cum a fost exploatat covorul: asezarea lui pe jos, întreținerea lui între spălări – aspirat regulat, expunerea la soare și poziționarea diferitelor obiecte de mobilier peste covor.</p>
    </mat-expansion-panel>
</mat-accordion>
